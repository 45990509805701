import React from 'react';
import { graphql } from 'gatsby';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';

const IndexPage = ({ data: { page } }) => {
  const { pageModules, seo } = page || {};

  return (
    <Layout>
      <PageMeta {...seo} />
      {pageModules && <ModuleZone {...pageModules} />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      seo {
        ...SEO
      }
      pageModules: modules {
        ...PageModules
      }
    }
  }
`;
